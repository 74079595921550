.oddsTable:nth-child(odd) {
    background-color: rgb(245, 245, 245) !important;
}

.loader {
    column-span: all;
}

.filterDropdown {
    margin-top: 7px !important;
    margin-bottom: 7px !important;
}