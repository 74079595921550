@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fill-info {
  color: #61a5fa;
  transition: all 0.1s ease-in-out;
}

.fill-error {
  color: #f87171;
  transition: all 0.1s ease-in-out;
}

.fill-error:hover {
  color: #f64e4e;
}

.fill-info:hover {
  color: #3f91f8;
}